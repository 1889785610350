// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$web_platform-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$web_platform-accent: mat.m2-define-palette(
  mat.$m2-pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$web_platform-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$web_platform-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $web_platform-primary,
      accent: $web_platform-accent,
      warn: $web_platform-warn,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($web_platform-theme);

@import "mat-icon-button-sizes/style.css";

:root {
  --main-height: calc(100vh - 64px);
}

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Karla", sans-serif;
}

.app-root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.mat-mdc-form-field {
  font-size: 0.875rem;

  input {
    font-size: 0.875rem;
  }

  label.mat-mdc-form-field-label {
    font-size: 0.875rem;
    top: 1.125rem;
  }

  &.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float
    .mat-mdc-form-field-label,
  &.mat-mdc-form-field-can-float
    .mat-input-server:focus
    + .mat-mdc-form-field-label-wrapper
    .mat-mdc-form-field-label {
    transform: translateY(-1.25em) scale(0.75);
    width: 133.33333%;
  }

  .mat-mdc-form-field-infix {
    min-height: 1.125rem;
  }
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
    .mat-mdc-form-field-infix {
    padding-top: 0.7rem;
    padding-bottom: 0.25rem;
  }
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 1.25rem;
    font-size: 0.875rem;
  }

  .mat-mdc-form-field-icon-prefix .mat-icon {
    width: 1rem;
    height: 1rem;
    font-size: 1.125rem;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white;
  }
}

@for $i from 0 through 24 {
  .mat-mdc-card.mat-elevation-z#{$i} {
    @include mat.elevation($i);
  }
}

.mat-toolbar {
  background-color: #eeeeee !important;
  color: #303030 !important;
}
